<template>
  <B-container class="pw-reset">
    <h1>Wachtwoord aanmaken | Create password</h1>
    <label class="d-flex">Wachtwoord | Password <img src="@/assets/img/info.svg" class="ml-1" v-b-tooltip.right.hover title="Het wachtwoord moet minstens 10 karakters lang zijn en minimaal een kleine letter, een hoofdletter, een cijfer en een speciaal teken bevatten | The password needs to be at least 10 characters long and contain at least a lowercase, uppercase, number and special character"></label>
    <B-input type="password" v-model="password" :state="pwState" @input="pwState = null" :disabled="disabled"/>
    <label class="mt-3">Herhaal wachtwoord | Repeat password</label>
    <B-input type="password" v-model="password2" :state="pwState2" @input="pwState2 = null" :disabled="disabled"/>
    <B-alert :show="showMessage" :variant="messageVariant" dismissible @dismissed="showMessage = false" class="mt-3">{{message}}</B-alert>
    <div class="text-center py-5">
      <B-button @click="pwReset" :disabled="disabled">Bevestigen | Confirm</B-button>
    </div>
  </B-container>
</template>

<script>
export default {
  name: "PasswordReset",
  data(){
    return{
      pwResetToken: "",
      password: "",
      password2: "",
      pwState: null,
      pwState2: null,
      showMessage: false,
      messageVariant: "danger",
      message: "",
      disabled: false
    }
  },
  methods:{
    async pwReset(){
      if(this.password !== this.password2){
        this.pwState = false
        this.pwState2 = false
        this.message = "Je wachtwoorden komen niet overeen | Your passwords don't match"
        this.messageVariant = "danger"
        this.showMessage = true
        return
      }
      /*
      Password regex rules: 
        - minimal 10 characters 
        - at least one uppercase letter
        - at least one lowercase letter
        - at least one number
        - at least one special character
      */
      const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&-])[A-Za-z\d@$#!%*?&-]{10,}$/;
      if(!pwRegex.test(this.password)){ //test regex
        this.pwState = false
        this.pwState2 = false
        this.message = "Het wachtwoord moet minstens 10 karakters lang zijn en minimaal een kleine letter, een hoofdletter, een cijfer en een speciaal teken bevatten | The password needs to be at least 10 characters long and contain at least a lowercase, uppercase, number and special character"
        this.messageVariant = "danger"
        this.showMessage = true
      }else{
        try{
          await this.axios.post(`${process.env.VUE_APP_API_URL}/api/appUsers/setNewPassword`,{
            resetToken: this.pwResetToken,
            password: this.password
          })
          this.disabled = true
          this.message = "Wachtwoord aangemaakt! Ga terug naar de app om in te loggen | Password created! Return to the app to login"
          this.messageVariant = "success"
          this.showMessage = true
        }catch(e){
          console.log("Password reset server error: ",e.response.data.message)
          this.message = e.response.data.message ? e.response.data.message : "Error"
          this.messageVariant = "danger"
          this.showMessage = true
        }
      }
    }
  },
  mounted(){
    this.pwResetToken = this.$route.query.t
  }
};
</script>

<style lang="scss" scoped>
.pw-reset{
  max-width: 500px;
  margin: 0 auto;
  padding-top: 48px;
  h1{
    font-size: 32px;
    font-weight: bold;
    color: #293a39;
    text-align: center;
    margin: 0 0 40px 0;
    line-height: 1;
  }
}
</style>
